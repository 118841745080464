import * as React from "react";
import {CookiesProvider} from "react-cookie";
import {Provider} from "react-redux";
import {persistor, store} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import {config} from "./config";
import {ConfigProvider as AppConfigProvider} from "./customs/providers";
import {ConfigProvider} from 'antd';
import {theme} from "./theme/theme";
import {BrowserRouter} from "react-router-dom";

export interface ProvidersProps {
    children: React.ReactNode;
}

export function Providers({children}: ProvidersProps) {
    const defaultSetOptions: any = config.cookies
    return (
        <React.StrictMode>
            <AppConfigProvider>
                <Provider store={store}>
                    <PersistGate persistor={persistor} loading={null}>
                        <BrowserRouter>
                            <CookiesProvider defaultSetOptions={defaultSetOptions}>
                                <ConfigProvider theme={theme}>
                                    {children}
                                </ConfigProvider>
                            </CookiesProvider>
                        </BrowserRouter>
                    </PersistGate>
                </Provider>
            </AppConfigProvider>
        </React.StrictMode>
    );
}
