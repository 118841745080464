import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {GetThunkAPI} from "@reduxjs/toolkit/dist/createAsyncThunk";
import {config} from "../../config";

// Define a type for the slice state
interface IState {
    store: any;
    storeDetail: any;
    stores: any;
    loading: boolean;
    status: any;
    message: any;
}

// Initial state
const initialState: IState = {
    store: null,
    storeDetail: null,
    stores: null,
    loading: false,
    status: null,
    message: null
};

// Async thunks

export const createStore = createAsyncThunk(
    'store/create',
    async (data: any, thunkAPI: GetThunkAPI<any>) => {
        try {
            const response = await config.api.createInstance({
                apiType: 'goSeller',
                token: data.token
            }).post('/store/create-store', data.store);
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to store';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getStoreDetails = createAsyncThunk(
    'store/details',
    async (data: any, thunkAPI: GetThunkAPI<any>) => {
        try {
            const response = await config.api.createInstance({
                apiType: 'goSeller',
                token: data.token
            }).get('/store/get-detail/' + data.userId);
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to fetch';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Slice
export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        clearStoreState: (state: IState) => {
            state.store = null;
            state.storeDetail = null;
            state.stores = null;
            state.loading = false;
            state.status = null;
            state.message = null;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(createStore.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(createStore.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.store = action.payload.data;
                state.status = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(createStore.rejected, (state: IState, action: any) => {
                state.loading = false;
                state.message = action.error.message;
            })
            .addCase(getStoreDetails.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(getStoreDetails.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.storeDetail = action.payload.data;
                state.status = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(getStoreDetails.rejected, (state: IState, action: any) => {
                state.loading = false;
                state.message = action.error.message;
            })

    },
});

export const {clearStoreState} = storeSlice.actions;
export default storeSlice.reducer;
