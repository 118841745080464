import React, {useEffect} from 'react';
import {ConfigContext} from '../../customs/contexts';
import {config} from '../../config';
import {useSecureCookie} from "../hooks";
import {getUrlParams} from "../../utils/utils";

const ConfigProvider = ({children}: { children: React.ReactNode }) => {
    const [getToken, setToken, removeToken] = useSecureCookie("token", true)
    const {continue: continueUrl, service} = getUrlParams();
    const token = getToken()

    useEffect(() => {
        if (token && token !== 'undefined') {
            window.location.href = continueUrl
        }
    }, [token]);

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigProvider;
