export const urlsConfigs = {
    accounts: {
        backend: 'https://ehb-backend-accounts.codencode.ae',
    },
    goSeller: {
        frontend: 'https://ehb-go-seller.codencode.ae/',
        backend: 'https://ehb-backend-go-seller.codencode.ae'
    },
    goSellerDashboard: {
        frontend: 'https://go-seller-dashboard.codencode.ae',
    }
}