import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {GetThunkAPI} from "@reduxjs/toolkit/dist/createAsyncThunk";
import {config} from "../../config";

// Define a type for the slice state
interface IState {
    dashboardType: any;
    dashboardTypeDetail: any;
    dashboardTypes: any;
    status: any;
    loading: boolean;
    message: any;
}

// Initial state
const initialState: IState = {
    dashboardType: null,
    dashboardTypeDetail: null,
    dashboardTypes: null,
    status: null,
    loading: false,
    message: null
};

// Async thunks

export const getAllDashboardTypes = createAsyncThunk(
    'dashboard-types/all',
    async (data: any, thunkAPI: GetThunkAPI<any>) => {
        try {
            const response = await config.api.createInstance({
                apiType: 'goSeller',
                token: data.token
            }).get('/dashboard-type/get-all');
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to fetch';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Slice
export const dashboardTypeSlice = createSlice({
    name: 'dashboard-types',
    initialState,
    reducers: {
        clearDashboardTypeState: (state: IState) => {
            return initialState
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(getAllDashboardTypes.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(getAllDashboardTypes.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.dashboardTypes = action.payload.data;
                state.status = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(getAllDashboardTypes.rejected, (state: IState, action: any) => {
                state.loading = false;
                state.message = action.error.message;
            })

    },
});

export const {clearDashboardTypeState} = dashboardTypeSlice.actions;
export default dashboardTypeSlice.reducer;
