import {combineReducers, configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';
import authReducer from './slices/auth.slice';
import storeReducer from './slices/store.slice';
import businessRoleReducer from './slices/business-role.slice';
import dashboardTypeReducer from './slices/dashboard-type.slice';
import storeTypeReducer from './slices/store-type.slice';
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['store', 'businessRole', 'dashboardType', 'storeType',]
};

const rootReducer = combineReducers({
    auth: authReducer,
    store: storeReducer,
    businessRole: businessRoleReducer,
    dashboardType: dashboardTypeReducer,
    storeType: storeTypeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER'],
            },
        }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type IRootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
