import {FC, lazy, LazyExoticComponent, Suspense} from "react";
import {Navigate} from "react-router-dom";
import loadingGif from "../assets/loading/loadEhb.gif"
import ProtectedLayout from "../layout/ProtectedLayout";
import MainLayout from "../layout/mainLayout";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
    (
        <Suspense fallback={
            <div
                className="d-flex justify-center align-center"
                style={{
                    height: "100vh",
                    width: "100%",
                    position: 'fixed',
                    left: "0",
                    top: "0",
                }}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <img src={loadingGif} width={150} style={{filter: "grayscale(1) brightness(8.5)"}} alt=""/>
                    <h3 style={{color: "#6f757b", marginTop: "-29px"}}>EHB</h3>
                </div>
            </div>
        }>
            <Component {...props} />
        </Suspense>

    );
const SignIn = Loadable(lazy(() => import("../pages/signIn")));
const SignUp = Loadable(lazy(() => import("../pages/signUp")));
const RestPassword = Loadable(lazy(() => import("../pages/resetPassword")));
const Store = Loadable(lazy(() => import("../pages/go-seller/store")));

export const routes: any = [
    {path: "/", element: <Navigate to="sign-in"/>},
    {
        path: "sign-in",
        element: <SignIn/>,
    },
    {
        path: "sign-up",
        element: <SignUp/>,
    },
    {
        path: "reset-password/:token",
        element: <RestPassword/>,
    },
    {
        path: "/",
        element: <ProtectedLayout/>,
        children: [
            {
                path: "go-seller/store-registration",
                element: <Store/>,
            },
        ]
    }
];
