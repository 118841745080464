import CryptoJS from 'crypto-js';
import {config} from "../config";

export const isValidHttpUrl = (urlString: string) => {
    let url;
    try {
        url = new URL(urlString);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export const slugToSentence = (slug: string) => {
    const words = slug.replace(/[-_]/g, ' ').split(' ');
    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const generateCustomId = () => {
    const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let customId: any = '';

    for (let i = 0; i < 9; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        customId += characters.charAt(randomIndex);
    }

    return customId;
}

export const getUrlParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const params: any = {};
    queryParams.forEach((value, key) => {
        params[key] = value;
    });
    return params;
}

const generateSecretKey = (): string => {
    const keyLength = 32; // 32 bytes = 256 bits (AES-256)
    const buffer = new Uint8Array(keyLength);
    crypto.getRandomValues(buffer);
    return Array.from(buffer, (byte) =>
        byte.toString(16).padStart(2, '0')
    ).join('');
};

export const encryptData = (data: any) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), config.auth.encryptKey).toString();
};

export const decryptData = (ciphertext: any) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, config.auth.encryptKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    if (/^[[{]/.test(decryptedData)) {
        try {
            return JSON.parse(decryptedData);
        } catch (e) {
            console.error("Decryption resulted in invalid JSON:", e);
        }
    }
    return decryptedData;
};
