export const theme = {
    token: {
        // colorPrimary: "#2F87EF",
        colorPrimary: "#1863C5",
        fontFamily: "Poppins,sans-serif",
        // colorInfo: "#1890FF",
        // colorError: "#FF4D4F",
        // colorSuccess: "#409D12",
        // colorWarning: "#FAAD14",
        // colorTextBase: "#264653",
    },
    components: {
        Button: {
            borderRadius: 5,
            controlHeight: 40,
            controlHeightLG: 48,
            controlHeightSM: 24,
            paddingContentHorizontal: 35,
            fontSize: 16,
            colorPrimaryHover: "none",
            border: "none",
            backgroundColor: "red"
        },
        Input: {
            controlHeight: 45,
            controlHeightLG: 45,
            controlHeightSM: 38,
            colorBorder: "#c4c4c4",
            borderRadius: 5,
            boxShadow: "none",
        },
        Select: {
            controlHeight: 45,
            controlHeightLG: 45,
            controlHeightSM: 38,
            colorBorder: "#c4c4c4",
            borderRadius: 5,
        },
        // Typography: { colorTextHeading: "#14142B" },
    },
};
