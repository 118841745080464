import React, {useEffect, useState} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import "./mainLayout.scss"
import {useSecureCookie} from "../customs/hooks";
import {useAppSelector} from "../store";
import {getUrlParams} from "../utils/utils";


const ProtectedLayout = () => {
    const navigate = useNavigate()
    const [getToken, setToken, removeToken] = useSecureCookie("token", true)
    const {user, loading, isLoggedIn} = useAppSelector(state => state.auth);
    const {continue: continueUrl, service} = getUrlParams();

    const token = getToken();

    useEffect(() => {
        if (!token || token === 'undefined') {
            navigate('/sign-in?continue=' + encodeURIComponent(continueUrl) + '&service=' + service)
        }
    }, [token]);

    return (
        <Outlet/>
    )
}

export default ProtectedLayout